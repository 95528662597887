import React from "react"

import SvgIcon from "@material-ui/core/SvgIcon"

const PinIcon = props => (
  <SvgIcon {...props} viewBox="0 0 51 51">
    <g>
      <path
        d="M25.5,0.5c-13.81,0-25,11.19-25,25s11.19,25,25,25s25-11.19,25-25S39.31,0.5,25.5,0.5z M25.5,47.38
		c-12.06,0-21.88-9.81-21.88-21.88c0-12.06,9.81-21.87,21.88-21.87s21.88,9.81,21.88,21.87C47.38,37.56,37.56,47.38,25.5,47.38z"
      />
      <g>
        <g>
          <path
            d="M25.5,9.58c-6.41,0-11.63,5.22-11.63,11.63c0,1.92,0.48,3.83,1.4,5.53l9.6,17.35
				c0.13,0.23,0.37,0.37,0.64,0.37c0.26,0,0.51-0.14,0.64-0.37l9.6-17.36c0.91-1.69,1.39-3.6,1.39-5.52
				C37.13,14.79,31.91,9.58,25.5,9.58z M25.5,27.02c-3.21,0-5.81-2.61-5.81-5.81s2.61-5.81,5.81-5.81s5.81,2.61,5.81,5.81
				S28.71,27.02,25.5,27.02z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default PinIcon
