import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TileLayer, Marker } from "react-leaflet"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import Grid from "@material-ui/core/Grid"

import Map from "../Map"
import CustomButton from "../buttons/CustomButton"
import PinIcon from "../icons/PinIcon"
import LangContext from "../../contexts/LangContext"

const useStyles = makeStyles(theme => ({
  locationBox: {
    height: "100%",
  },
  media: {
    height: 240,
    minHeight: 240,

    [theme.breakpoints.down("sm")]: {
      height: 150,
      minHeight: 150,
    },
  },
  contentWrapper: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  content: {
    height: "100%",
  },

  addressLine: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,

    [theme.breakpoints.up("md")]: {
      minHeight: 75,
    },
  },

  actions: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },

  buttonLine: {},

  schedule: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

const LocationMapCard = ({ location, onSelect, showLinkButton, showMap, showSchedule }) => {
  const classes = useStyles()
  const lang = useContext(LangContext)

  const data = useStaticQuery(query)

  const contactPage = data.contactPages.edges
    .map(r => r.node)
    .filter(tmp => tmp.lang === lang.locale)
    .pop()

  return (
    <Card elevation={0} square className={classes.locationBox}>
      {showMap && <div style={{ minHeight: 150 }}>
        <CardMedia
          className={classes.media}
          component={Map}
          center={[
            location.data.coordinates.latitude,
            location.data.coordinates.longitude,
          ]}
          zoom={17}
          scrollWheelZoom={false}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            position={[
              location.data.coordinates.latitude,
              location.data.coordinates.longitude,
            ]}
          />
        </CardMedia>
      </div>
      }
      <CardContent className={classes.contentWrapper}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          className={classes.content}
        >
          <Grid item className={classes.addressLine}>
            <div>
              <PinIcon />
            </div>
            <div style={{ marginLeft: 10 }}>
              <div className={classes.addressText}>
                <span style={{ fontWeight: 700 }}>{location.data.city.text}</span>
                &nbsp;-&nbsp;
                {location.data.address.text}
              </div>
              {location.data.schedule?.length > 0 && <div className={classes.scheduleText}>
                <span style={{ fontWeight: 700 }}>Program</span>
                &nbsp;-&nbsp;
                {location.data.schedule.map(s => `${s.key} - ${s.value}`)}
              </div>}

            </div>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions className={classes.actions}>
        <CustomButton
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => onSelect(location.uid)}
        >
          Programează-te acum!
        </CustomButton>
        {showLinkButton && (
          <CustomButton
            component={Link}
            size="small"
            color="primary"
            variant="contained"
            to={`/${contactPage.uid}/${location.uid}/`}
          >
            Vizitează spitalul
          </CustomButton>
        )}
      </CardActions>
    </Card>
  )
}

export default LocationMapCard

const query = graphql`
  query LocationMapCardQuery {
    contactPages: allPrismicContact {
      edges {
        node {
          lang
          uid
        }
      }
    }
  }
`

LocationMapCard.defaultProps = {
  onSelect: () => { },
  showLinkButton: false,
  showMap: true,
  showSchedule: true,
}

LocationMapCard.propTypes = {
  location: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  showLinkButton: PropTypes.bool,
  showMap: PropTypes.bool,
  showSchedule: PropTypes.bool,
}
