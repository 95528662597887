import React from "react"
import PropTypes from "prop-types"
import makeStyles from '@material-ui/core/styles/makeStyles'
import { MapContainer } from "react-leaflet"

import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"

import wazeLogo from "../img/waze_logo.png"
import googleMapsLogo from "../img/google_maps_logo.png"
import uberLogo from "../img/uber_logo.png"

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: "none",
    borderRadius: 0,
    padding: 0,
    background: theme.palette.common.white,

    "&:hover": {
      background: theme.palette.common.white,
    },
  },
}))

const NavigationButtons = ({ position }) => {
  const classes = useStyles()

  const [lat, lng] = position

  return (
    <div className="leaflet-bottom leaflet-right">
      <div className="leaflet-control leaflet-bar">
        <Button
          variant="contained"
          className={classes.button}
          component={Link}
          href={`https://www.waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&zoom=17`}
        >
          <img
            src={wazeLogo}
            alt="Waze Directions"
            style={{ width: 50 }}
            width="50"
            height="13"
          />
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          component={Link}
          href={`http://maps.google.com/maps?daddr=${lat},${lng}`}
        >
          <img
            src={googleMapsLogo}
            alt="Google Maps Directions"
            width="50"
            height="10"
            style={{ width: 50 }}
          />
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          component={Link}
          href={`https://m.uber.com/ul/?client_id=C6YQn2u5cbiE1Hd_xeQ8Yt_UE1GNPnvH&dropoff[latitude]=${lat}&dropoff[longitude]=${lng}`}
        >
          <img
            src={uberLogo}
            alt="Call an Uber"
            style={{ width: 30 }}
            width="30"
            height="11"
          />
        </Button>
      </div>
    </div>
  )
}

const Map = props => {
  const { children } = props
  const { center } = props

  return (
    <>
      {typeof window !== "undefined" ? (
        <>
          <MapContainer {...props}>
            {children}
            <NavigationButtons position={center} />
          </MapContainer>
        </>
      ) : null}
    </>
  )
}

export default Map

Map.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
